import _schemas2 from "./schemas";
import _JSONLD2 from "./JSONLD";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _schemas = _schemas2;
Object.keys(_schemas).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _schemas[key];
    }
  });
});
var _JSONLD = _JSONLD2;
Object.defineProperty(exports, "JSONLD", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_JSONLD).default;
  }
});

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

export default exports;
export const __esModule = exports.__esModule,
      JSONLD = exports.JSONLD;