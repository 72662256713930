import _AggregateRating2 from "./AggregateRating";
import _Author2 from "./Author";
import _Generic2 from "./Generic";
import _GenericCollection2 from "./GenericCollection";
import _Location2 from "./Location";
import _Product2 from "./Product";
import _Rating2 from "./Rating";
import _Review2 from "./Review";
import _Reviews2 from "./Reviews";
import _ItemReviewed2 from "./ItemReviewed";
import _Question2 from "./Question";
import _Answer2 from "./Answer";
import _Graph2 from "./Graph";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _AggregateRating = _AggregateRating2;
Object.defineProperty(exports, "AggregateRating", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_AggregateRating).default;
  }
});
var _Author = _Author2;
Object.defineProperty(exports, "Author", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Author).default;
  }
});
var _Generic = _Generic2;
Object.defineProperty(exports, "Generic", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Generic).default;
  }
});
var _GenericCollection = _GenericCollection2;
Object.defineProperty(exports, "GenericCollection", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_GenericCollection).default;
  }
});
var _Location = _Location2;
Object.defineProperty(exports, "Location", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Location).default;
  }
});
var _Product = _Product2;
Object.defineProperty(exports, "Product", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Product).default;
  }
});
var _Rating = _Rating2;
Object.defineProperty(exports, "Rating", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Rating).default;
  }
});
var _Review = _Review2;
Object.defineProperty(exports, "Review", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Review).default;
  }
});
var _Reviews = _Reviews2;
Object.defineProperty(exports, "Reviews", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Reviews).default;
  }
});
var _ItemReviewed = _ItemReviewed2;
Object.defineProperty(exports, "ItemReviewed", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_ItemReviewed).default;
  }
});
var _Question = _Question2;
Object.defineProperty(exports, "Question", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Question).default;
  }
});
var _Answer = _Answer2;
Object.defineProperty(exports, "Answer", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Answer).default;
  }
});
var _Graph = _Graph2;
Object.defineProperty(exports, "Graph", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Graph).default;
  }
});

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

export default exports;