import _JSONLDAbstractNode4 from "../JSONLDAbstractNode";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _get = function get(object, property, receiver) {
  if (object === null) object = Function.prototype;
  var desc = Object.getOwnPropertyDescriptor(object, property);

  if (desc === undefined) {
    var parent = Object.getPrototypeOf(object);

    if (parent === null) {
      return undefined;
    } else {
      return get(parent, property, receiver);
    }
  } else if ("value" in desc) {
    return desc.value;
  } else {
    var getter = desc.get;

    if (getter === undefined) {
      return undefined;
    }

    return getter.call(receiver);
  }
};

var _JSONLDAbstractNode2 = _JSONLDAbstractNode4;

var _JSONLDAbstractNode3 = _interopRequireDefault(_JSONLDAbstractNode2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var AggregateRating = function (_JSONLDAbstractNode) {
  _inherits(AggregateRating, _JSONLDAbstractNode);

  function AggregateRating() {
    _classCallCheck(this, AggregateRating);

    return _possibleConstructorReturn(this, (AggregateRating.__proto__ || Object.getPrototypeOf(AggregateRating)).apply(this, arguments));
  }

  _createClass(AggregateRating, [{
    key: "getJSON",
    value: function getJSON() {
      var isFirstChildNode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var schema = arguments[1];

      var parseChildren = _get(AggregateRating.prototype.__proto__ || Object.getPrototypeOf(AggregateRating.prototype), "parseChildren", this).call(this);

      var details = _extends({
        "@type": "AggregateRating"
      }, schema);

      return isFirstChildNode ? _extends.apply(undefined, [details].concat(_toConsumableArray(parseChildren))) : _extends.apply(undefined, [{
        aggregateRating: details
      }].concat(_toConsumableArray(parseChildren)));
    }
  }]);

  return AggregateRating;
}(_JSONLDAbstractNode3.default);

exports.default = AggregateRating;
export default exports;