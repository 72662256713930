import _react3 from "react";
import _propTypes3 from "prop-types";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.JSONLD = undefined;

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = _react3;

var _react2 = _interopRequireDefault(_react);

var _propTypes = _propTypes3;

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutProperties(obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
}

var JSONLD = function JSONLD(props) {
  var json = null;

  if (props.children) {
    var ChildClass = props.children.type;

    var _props$children$props = props.children.props,
        children = _props$children$props.children,
        type = _props$children$props.type,
        schema = _objectWithoutProperties(_props$children$props, ["children", "type"]);

    var firstChild = new ChildClass(props.children.props).getJSON(true, schema);
    json = ChildClass.name === "JSONLDNodeCollection" ? _extends({
      "@context": "https://schema.org/"
    }, _defineProperty({}, type, firstChild)) : _extends({
      "@context": "https://schema.org/"
    }, firstChild);
  }

  return props.dangerouslyExposeHtml ? _react2.default.createElement("script", {
    type: "application/ld+json",
    dangerouslySetInnerHTML: {
      __html: JSON.stringify(json)
    }
  }) : _react2.default.createElement("script", {
    type: "application/ld+json"
  }, JSON.stringify(json));
};

exports.JSONLD = JSONLD;
JSONLD.propTypes = {
  additionalType: _propTypes2.default.object,
  dangerouslyExposeHtml: _propTypes2.default.bool
};
JSONLD.defaultProps = {
  dangerouslyExposeHtml: false
};
exports.default = JSONLD;
export default exports;